/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import { Link } from 'gatsby'
import { Fragment } from 'react'
import ImgOrigem from '../assets/images/lp/home/origem.png'
import ImgElos from '../assets/images/lp/home/elos-que-nos-unem.png'
import ImgOrigemBertoni from '../assets/images/lp/origem/menina-origem.png'

import {
  boxContent,
  mb100,
  btnSaibaMais,
  DMobile,
  DDesktop,
  cta1,
  boxContent2,
  imgBox,
} from '../assets/styles/ContentSection.styles'

const ContentSection = () => {
  return (
    <Fragment>
      <section css={boxContent}>
        <img
          css={DMobile}
          src={ImgOrigem}
          alt='estudante escrevendo'
          style={{ width: '100%' }}
        />
        <Container>
          <Row css={mb100}>
            <Col css={DDesktop} lg={6} md={6} sm={12}>
              <img
                src={ImgOrigem}
                alt='estudante escrevendo'
              />
            </Col>
            <Col lg={5} md={5} sm={12}>
              <h2>Origem</h2>
              <p>
                Orientado pelas melhores práticas de ensino do mundo, o Bertoni
                Elos foi pensado como escola-modelo apoiada numa proposta
                pedagógica eficiente e inovadora. Uma bandeira de excelência
                capaz de desenvolver e manter o Elos, de maneira sólida, como
                uma das maiores redes de escolas do país.
              </p>
              <div>
                <Link css={btnSaibaMais} to='/origem'>
                  <span>Saiba mais</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section css={cta1}>
        <img
          css={DMobile}
          src={ImgElos}
          alt=''
          style={{ width: '100%' }}
        />
        <Container>
          <Row>
            <Col
              lg={6}
              sm={12}
            >
              <div
                css={boxContent2}
              >
                <h2>Elos que nos unem</h2>
                <p>
                  É aqui, nos valores, onde fincamos as bandeiras da ética e da
                  colaboração como guias para todas as atividades desenvolvidas
                  no colégio, seja por alunos, professores ou colaboradores.
                </p>
              </div>
              <div>
                <Link css={btnSaibaMais} to='/elos'>
                  <span>Saiba mais</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <div css={[imgBox, DDesktop]}>
          <img
            src={ImgElos}
            alt='/'
          />
        </div>
      </section>
    </Fragment>
  )
}

export default ContentSection
