/** @jsx jsx */
import React, { Fragment } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import 'react-modal-video/css/modal-video.min.css'
import { jsx } from '@emotion/react'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import ImgElos from '../assets/images/lp/home/elos.png'

import {
  ElosContent,
  imgBoxL,
  boxContentL,
  DDesktop,
} from '../assets/styles/ElosHome.styles'

const ElosHome = () => {
  return (
    <Fragment>
      <section css={ElosContent}>
        <div
          css={[imgBoxL, DDesktop]}
        >
          <img src={ImgElos} alt='/' />
        </div>
        <Container>
          <Row>
            <Col lg={6} sm={12} />
            <Col lg={6} sm={12} >
              <div css={boxContentL}>
                <Accordion allowZeroExpanded>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Elos que nos movem
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Cultivar um ambiente seguro e colaborativo em que
                        tradição, disciplina e criatividade se unem para formar
                        cidadãos íntegros e responsáveis. Formar pessoas
                        preparadas para liderar e prosperar. Promover excelência
                        acadêmica, desenvolvimento integral e ética nas relações
                        humanas. Ser uma escola eficiente profundamente
                        conectada às demandas da comunidade.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Elos que nos inspiram
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Erguer uma bandeira de excelência, inspirando líderes
                        fortes, íntegros e responsáveis. Criar e desenvolver uma
                        das maiores redes de escolas do país apoiada em uma
                        proposta pedagógica eficiente e inovadora.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Elos que nos orientam
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        A escola pensada como lugar de interação e excelência.
                        Território onde fincamos as bandeiras da ética e da
                        colaboração como guias de uma jornada de crescimento
                        apoiada em orientação, disciplina, criatividade,
                        parceria, empatia e resiliência. Uma escola eficiente
                        profundamente conectada às demandas da comunidade,
                        pronta para "dar rumo na vida” de seus alunos -
                        expoentes de uma nova geração de cidadãos prósperos e
                        responsáveis. Bem-vindo a uma experiência que irá
                        transformar sua vida.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default ElosHome
