/** @jsx jsx */
import { css } from '@emotion/react'

export const sliderHeader = css`
  font-family: "normalidad-variable", sans-serif;
  h1 {
    font-weight: 600;
    font-size: 80px;
    line-height: 120%;
    color: #312E2D;
    font-variation-settings: "wght" 600;
    width: 100%;
    max-width: 475px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #312E2D;
    margin-bottom: 32px;
    width: 100%;
    max-width: 475px;
    text-transform: none;
    letter-spacing: 0px;
  }
  a {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;
    font-variation-settings: "wght" 700;
    background: #00854E;
    width: 156px;
    height: 52px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
      color: #ffffff;
      background: #312E2D;
      text-decoration: none;
    }
    :active {
      background: #F7FF53;
      color: #312E2D;
    }
  }
  @media (min-width: 320px) and (max-width: 767px) {
    h1 {
      font-size: 56px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    h1 {
      font-size: 56px;
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    h1 {
      font-size: 56px;
    }
  }
`

