/** @jsx jsx */
import { css } from '@emotion/react'

export const sliderHome = css` 
  background: #F9F9F9;
  .swiper-slide {
    width: 60% !important;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    .swiper-slide {
    width: 107% !important;
  }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .swiper-slide {
    width: 107% !important;
  }
  }
`

