/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import { Link } from 'gatsby'
import ArrowUp from '../assets/images/lp/icons/arrow-up.svg'

import {
  EstruturaHomeContent,
  headerContent,
  cardPreVestibular,
  cardFundamental,
  cardMedio,
  pdR,
  pdL,
} from '../assets/styles/EstruturaHome.styles'

const TurmasHome = () => {
  return (
    <section css={EstruturaHomeContent}>
      <Container>
        <Row css={headerContent}>
          <Col data-aos='fade-down' lg={12} md={12} sm={12}>
            <h2>Turmas</h2>
            <p>
              Conheça nossas turmas de pré-vestibular, ensino médio e infantil,
              pensadas para atender às necessidades e potenciais de cada aluno.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={1} md={1} sm={12} />
          <Col css={pdR} lg={6} md={6} sm={12}>
            <div
              data-aos-offset='200'
              data-aos='fade-down'
              data-aos-easing='ease-in-sine'
              css={cardPreVestibular}
            >
              <h4>Pré-Vest</h4>
              <p>Tudo o que você precisa para ser protagonista está aqui.</p>
              <Link to='/turmas'>
                Saiba mais <img src={ArrowUp} />
              </Link>
            </div>
            <div data-aos-offset='400'
                data-aos='fade-right'
                data-aos-easing='ease-in-out' css={cardFundamental}>
              <h4>Fundamental</h4>
              <p>Vem com a gente inventar outro futuro.</p>
              <Link to='/turmas'>
                Saiba mais <img src={ArrowUp} />
              </Link>
            </div>
          </Col>
          <Col data-aos-offset='500'
                data-aos='fade-left'
                data-aos-easing='ease-in-out' css={pdL} lg={4} md={4} sm={12}>
            <div css={cardMedio}>
              <h4>Ensino Médio</h4>
              <p>Conecte seu talento à inovação e transforme.</p>
              <Link to='/turmas'>
                Saiba mais <img src={ArrowUp} />
              </Link>
            </div>
          </Col>
          <Col lg={1} md={1} sm={12} />
        </Row>
      </Container>
    </section>
  )
}

export default TurmasHome
